import { Modal, Box, Paper, List, ListItem, ListItemText } from '@material-ui/core';

export function FirmwareDialog(props) {
    const { onClose, open, onInputChange, devicesSelected } = props;
  
    const handleListItemClick = (devices, firmware) => {
      console.log("click: " + firmware._id + " " + firmware.version + " devices: " + devices.length)
      onInputChange('devices', devicesSelected)
      onInputChange('updateFirmware', firmware)
      onInputChange('ready', true)
    };
  
    //var update_firmwares = props.firmwares.filter((firmware) => firmware.released);
    var update_firmwares = props.firmwares;
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    };
  
    return (
      <Modal hidebackdropx="true" onClose={onClose} open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      >
        <Box sx={{...style}}>
          <h4 id="child-modal-title">Firmware</h4>
          <Paper style={{maxHeight: 200, overflow: 'auto'}}>
        <List sx={{ pt: 0 }} id="child-modal-description">
          {update_firmwares.map((firmware) => (
            <ListItem button onClick={() => handleListItemClick(devicesSelected, firmware)} key={firmware._id}>
              <ListItemText primary={firmware.version} />
            </ListItem>
          ))}
        </List>
        </Paper>
        </Box>
      </Modal>
    );
  }
  