import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SortFilter } from "../SortFilter/SortFilter";

import { Checkbox } from "@material-ui/core";

import { authService } from "../../services/auth";
import { webSocketService } from "../../services/websocket";
import { baseurl } from "../../services/backend";
import { databaseService } from "../../services/database";

import { FirmwareDialog } from "../Dialogs/FirmwareDialog";
import { CreateDialog } from "../Dialogs/CreateDialog";
import { ConfirmationDialog } from "../Dialogs/ConfirmationDialog";
import { SetPassphraseDialog } from "../Dialogs/SetPassphraseDialog";

function firmwaresUpdate(devices, firmware) {
  console.log("firmwaresUpdate");
  var newToken = sessionStorage.getItem("token");
  try {
    if (!newToken) {
      throw new Error("Invalid token");
    }

    console.log("for each device");
    devices.forEach((device) => {
      console.log(device.devid);
      databaseService.putFirmware(newToken, device._id, firmware._id);
    });
  } catch (error) {
    console.log(error);
  }
}

// [{devid: "CTR02-352656102740753", imei: "352656102740753"}, {devid: "test-dev-11", imei: "352656106312518"}, {devid: "test-dev-12", imei: "352656106312393"}, {devid: "test-dev-13", imei: "352656106312534"}, {devid: "test-dev-3", imei: "352656102740977"}, {devid: "test-dev-99", imei: "1234"}]

function dateConvert(d) {
  var dt = new Date(d);
  return dt.toLocaleString();
}

/*
function selectDevice(e) {
  var row = $(e.target).parents('tr');

  if (row.hasClass('check-disabled'))
    return;

  var checked = !(row[0].dataset['checked'] == 'true');

  row[0].dataset['checked'] = checked;

  var icon = row.find('i');
  icon.text(checked ? 'check_box' : 'check_box_outline_blank');

  refreshSelectAllBtnIcon();
}*/

function Progress(props) {
  if (props.total > 0) {
    var percent = Math.round((props.current * 100) / props.total);
    return <div title={props.file}>{percent}%</div>;
  } else return <div />;
}

function generateCertificate(devices) {
  console.log("generateCertificate");
  try {
    console.log("for each device");
    devices.forEach((device) => {
      console.log(device._id + ":" + device.devid);
      databaseService.generateCertficateDevice(device.devid);
    });
  } catch (error) {
    console.log(error);
  }
}

function devicesDelete(devices) {
  console.log("devicesDelete");
  try {
    console.log("for each device");
    devices.forEach((device) => {
      console.log(device._id + ":" + device.devid);
      databaseService.deleteDevice(device._id);
    });
  } catch (error) {
    console.log(error);
  }
}

const CertificateCell = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  var expireDate =
    "certificate" in props.device
      ? dateConvert(props.device.certificate.expiration)
      : "";

  var inProgress =
    "certificate" in props.device &&
    (props.device.certificate.privcert_assign ||
      props.device.certificate.privkey_assign ||
      props.device.certificate.cacert_assign)
      ? true
      : false;
  var assignedStyle = inProgress ? { color: "red" } : {};

  return (
    <td onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div style={{ width: "100%", height: "100%" }}>
        {isHovering && "certificate" in props.device ? (
          <div style={assignedStyle}>
            {props.device.certificate.privcert_assign ||
            props.device.certificate.privkey_assign ||
            props.device.certificate.cacert_assign ? (
              <div>
                Transferring:
                {props.device.certificate.cacert_assign ? " cacert " : ""}
                {props.device.certificate.privcert_assign ? " privcert " : ""}
                {props.device.certificate.privkey_assign ? "  privkey " : ""}
              </div>
            ) : (
              "Complete"
            )}
          </div>
        ) : (
          <div style={assignedStyle}>
            {inProgress ? "In Progress" : expireDate}
          </div>
        )}
      </div>
    </td>
  );
};

export function Devices(props) {
  const [devicesSelected, setDevicesSelected] = React.useState(0);
  const [matchFirmwares, setMatchFirmwares] = React.useState([]);
  const [page, setPage] = useState(1);
  const [stride /*, setStride*/] = useState(10);
  const [count, setCount] = useState(0);
  const [devices, setDevices] = useState("");
  const [textQuery, setTextQuery] = useState("");
  const mounted = React.useRef(false);
  const history = useHistory();
  const [refresh, setRefresh] = useState(0);

  const [dialogState, setDialogState] = useState({
    openDialog: null,
    deviceId: "",
    imei: "",
    updateFirmware: null,
    devices: [],
    ready: false,
  });

  let startDevice = (page - 1) * stride;
  let endDevice = page * stride - 1;
  let startFirmware = 0;
  let endFirmware = -1;

  useEffect(() => {
    mounted.current = true;
    console.log("Devices useEffect");
    const messageHandler = (evt) => {
      console.log("ws message: " + evt.type + "," + evt.data);
      if (evt.data) {
        console.log(evt.data.update);
        console.log("set timeout");
        setTimeout(() => {
          //getDevices(newToken, startDevice, endDevice, textQuery)
          setRefresh(Date.now());
        }, 2000);
      }
    };

    if (dialogState.ready) {
      handleSubmit();
      setDialogState((prevState) => ({
        ...prevState,
        ready: false,
      }));
    }

    webSocketService.subscribe(messageHandler, "devices");

    try {
      databaseService
        .getDevices(authService.getToken(), startDevice, endDevice, textQuery)
        .then((result) => {
          if (mounted.current) {
            console.log("count: " + result.count + "," + result.devices.length);

            Promise.all(
              result.devices.map((device) => {
                return databaseService.getCertificate(
                  authService.getToken(),
                  device.devid
                );
              })
            )
              .then((data) => {
                console.log(data);
                data.forEach((cert, index) => {
                  if (cert.count > 0) {
                    console.log(cert.certificates[0]);
                    result.devices[index].certificate = cert.certificates[0];
                  }
                });
                setCount(result.count);
                setDevices(result.devices);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("redirecting to /login");
          authService.clrToken();
          history.push("/login");
        });
    } catch (error) {
      console.log(error);
      history.push("/login");
    }

    return () => {
      webSocketService.unsubscribe(messageHandler);
      mounted.current = false;
    };
  }, [
    history,
    startDevice,
    endDevice,
    textQuery,
    page,
    refresh,
    dialogState.ready,
  ]);

  const onSearchChange = (textQuery) => {
    setTextQuery(textQuery);
  };

  if (!devices) {
    return <div>Loading...</div>;
  }

  const handleOpenDialog = (dialog) => {
    setDialogState((prevState) => ({
      ...prevState,
      openDialog: dialog,
    }));
  };

  const handleCloseDialog = () => {
    setDialogState((prevState) => ({
      ...prevState,
      openDialog: null,
    }));
  };

  const handleInputChange = (name, value) => {
    setDialogState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { openDialog, ...formData } = dialogState;

    if (dialogState.openDialog === "assign") {
      firmwaresUpdate(dialogState.devices, dialogState.updateFirmware);
      setDialogState((prevState) => ({
        ...prevState,
        devices: [],
        updateFirmware: null,
      }));
    }

    if (dialogState.openDialog === "create") {
      databaseService.deviceCreate(dialogState.deviceId, dialogState.imei);
      setDialogState((prevState) => ({
        ...prevState,
        deviceId: [],
        imei: null,
      }));
    }

    if (dialogState.openDialog === "delete") {
      devicesDelete(dialogState.devices);
      setDialogState((prevState) => ({
        ...prevState,
        devices: [],
      }));
    }

    if (dialogState.openDialog === "set-pass") {
      databaseService.setPassphrase(dialogState.passphrase);
      setDialogState((prevState) => ({
        ...prevState,
        passphrase: [],
      }));
    }

    handleCloseDialog();
  };

  const countSelectedDevices = () => {
    setDevicesSelected(devices.filter((device) => device.checked));
  };

  const deviceAction = (evt) => {
    console.log("do action: " + evt);

    console.log("loading token");
    var newToken = sessionStorage.getItem("token");

    try {
      if (evt === "assign-m1" || evt === "assign-b1") {
        console.log(
          "Requesting firmwares [" +
            newToken +
            "] " +
            startFirmware +
            " -> " +
            endFirmware
        );
        databaseService
          .getFirmwares(newToken, startFirmware, endFirmware)
          .then((result) => {
            console.log("count: " + result.count);
            // Sort by version number
            var fws = result.firmwares
              .map((a) => {
                a.version = a.version.replace(/\d+/g, (n) => +n + 100000);
                return a;
              })
              .sort((a, b) => {
                if (a.version > b.version) return -1;
                else return 1;
              })
              .map((a) => {
                a.version = a.version.replace(/\d+/g, (n) => +n - 100000);
                return a;
              });

            if (evt === "assign-m1") {
              console.log(fws);
              setMatchFirmwares(
                fws.filter((firmware) => firmware.target === "m1")
              );
              handleOpenDialog("assign");
            }
            if (evt === "assign-b1") {
              setMatchFirmwares(
                fws.filter((firmware) => firmware.target === "b1")
              );
              handleOpenDialog("assign");
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("redirecting to /login");
            authService.clrToken();
            history.push("/login");
          });
      }
      if (evt === "create") {
        handleOpenDialog("create");
      }
      if (evt === "delete") {
        handleOpenDialog("delete");
      }
      if (evt === "set-pass") {
        handleOpenDialog("set-pass");
      }
      if (evt === "gen-cert") {
        generateCertificate(devicesSelected);
      }
    } catch (error) {
      console.log(error);
      history.push("/login");
    }
  };

  const handleCBChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    devid
  ) => {
    console.log("cb change");
    var device = devices.find((device) => device.devid === devid);
    if (device) {
      console.log(event.target.checked);
      device.checked = event.target.checked;
    }
    countSelectedDevices();
  };

  const bulkItems = [
    { text: "Assign 9160 firmware", action: "assign-m1", mode: "selected" },
    { text: "Assign 52840 firmware", action: "assign-b1", mode: "selected" },
    { text: "Create device", action: "create", mode: "none" },
    { text: "Delete device", action: "delete", mode: "selected" },
    { text: "Set passphrase", action: "set-pass", mode: "none" },
    { text: "Generate certficate", action: "gen-cert", mode: "selected" },
  ];

  return (
    <Container fluid="true" className="Container">
      <SortFilter
        bulkItems={bulkItems}
        onSearchChange={onSearchChange}
        setPage={setPage}
        itemsSelected={devicesSelected.length}
        action={deviceAction}
        currentPage={page}
        count={count}
        stride={stride}
        title="Devices"
      />
      <FirmwareDialog
        open={dialogState.openDialog === "assign"}
        onClose={handleCloseDialog}
        onInputChange={handleInputChange}
        devices={devices}
        devicesSelected={devicesSelected}
        firmwares={matchFirmwares}
      />
      <CreateDialog
        open={dialogState.openDialog === "create"}
        onClose={handleCloseDialog}
        onInputChange={handleInputChange}
        deviceId={dialogState.deviceId}
        imei={dialogState.imei}
      />
      <ConfirmationDialog
        open={dialogState.openDialog === "delete"}
        onClose={handleCloseDialog}
        onInputChange={handleInputChange}
        devicesSelected={devicesSelected}
      />
      <SetPassphraseDialog
        open={dialogState.openDialog === "set-pass"}
        onClose={handleCloseDialog}
        onInputChange={handleInputChange}
      />
      <Table striped bordered hover variant="signetik" className="devices-list">
        <thead>
          <tr />
          <tr>
            <td variant="signetik">SELECT</td>
            <td variant="signetik">DEVID</td>
            <td variant="signetik">IMEI</td>
            <td variant="signetik">9160 FW</td>
            <td variant="signetik">52xx FW</td>
            <td variant="signetik">9160 FW assigned</td>
            <td variant="signetik">52xx FW assigned</td>
            <td variant="signetik">Certificate Expiry</td>
            <td variant="signetik">Last Connection</td>
            <td variant="signetik">Description</td>
            <td variant="signetik">Progress</td>
          </tr>
        </thead>
        <tbody className="table-content">
          {devices.map((device, i) => (
            <tr data-_id={device._id} key={device._id}>
              <td className="checkbox-cell">
                <label className="checkbox">
                  <Checkbox
                    className="checkbox-icon"
                    color="default"
                    onChange={(event) => {
                      handleCBChange(event, device.devid);
                    }}
                  />
                </label>
              </td>
              <td>
                <div className="device-id">{device.devid}</div>
              </td>
              <td>{device.imei}</td>
              <td>{device.m1fw}</td>
              <td>{device.b1fw}</td>
              <td className={`${device.m1fw_depends ? "depends" : ""}`}>
                {device.m1fw_assigned}
              </td>
              <td className={`${device.b1fw_depends ? "depends" : ""}`}>
                {device.b1fw_assigned}
              </td>
              <CertificateCell device={device}></CertificateCell>
              <td>{dateConvert(device.modified)}</td>
              <td>{device.desc}</td>
              <td>
                <Progress
                  current={device.progress.current}
                  total={device.progress.total}
                  file={device.progress.file}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
