import { baseuri, wsproto } from './backend';

class WebSocketService {
    constructor() {
      this.socket = null;
      this.observers = [];

      this.connect(wsproto + "://" + baseuri + "/ws")
    }

    
  
    // Method to subscribe observers
    subscribe(observer, msg) {
      this.observers.push([observer, msg]);
    }
  
    // Method to unsubscribe observers
    unsubscribe(observer) {
      this.observers = this.observers.filter(obs => obs[0] !== observer);
    }
  
    // Notify all observers about an event
    notifyObservers(evt) {
      if (evt.data) {
        evt.data = JSON.parse(evt.data.replace(/'/g, '"'));
      }
      this.observers.forEach(observer => {
        if (observer[1]) {
          if (evt.data && evt.data.update && evt.data.update === observer[1]) {
            observer[0](evt);
          }
        }
        else {
          observer(evt);
        }
      })
    }
  
    connect(url) {
      console.log('Create websocket ' + url)
      this.socket = new WebSocket(url);
  
      this.socket.onopen = () => {
        console.log("ws open")
        this.notifyObservers({ type: 'open' });
      };
  
      this.socket.onmessage = (event) => {
        console.log("ws message")
        this.notifyObservers({ type: 'message', data: event.data });
      };
  
      this.socket.onclose = (event) => {
        console.log("ws close")
        this.notifyObservers({ type: 'close', reason: event.reason });
        setTimeout(() => {this.connect(url)}, 15000);
      };
  
      this.socket.onerror = (error) => {
        console.log("ws errors")
        this.notifyObservers({ type: 'error', error });
      };
    }

    isConnected() {
        return this.socket && this.socket.readyState === WebSocket.OPEN
    }
  
    sendMessage(message) {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        console.error("Socket not connected");
        return;
      }
      this.socket.send(message);
    }
  
    disconnect() {
      if (this.socket) {
        this.socket.close();
      }
    }
  }
  
  // Exporting a singleton instance to be used across the application
  export const webSocketService = new WebSocketService();
  