import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert"
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from "react"

function AddUserDialog(props) {
    const { onClose/*, selectedValue*/, open } = props;
  
    const handleClose = () => {
        onClose();
    };
      
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    };
  
    return (
      <Modal hideBackdropx onClose={handleClose} open={open} onBackdropClick={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      >
        <Box sx={{...style}}>
          <h4 id="child-modal-title">Firmware</h4>
          <Paper style={{maxHeight: 200, overflow: 'auto'}}>
            xyz
        </Paper>
        </Box>
      </Modal>
    );
  }
  

export function Admin(props) {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        console.log("handleClose");
        setOpen(false);
      };
    
    return <div className="Login">
        <AddUserDialog open={open} onClose={handleClose} />
          <Form>
      <Button block size="lg" variant="signetik" onClick={() => {setOpen(true)}}>
        Add User
      </Button></Form>
        </div>;
}