import React, {useState, useEffect, useRef} from "react"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert"

import "bootstrap/dist/css/bootstrap.css";
import "./Login.css";
import { authService } from "../../services/auth";
import { useHistory } from "react-router-dom"

import { baseurl } from '../../services/backend';

export default function Login(props) {
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const history = useHistory()
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    if (validToken && mounted.current) {
      history.push(props.redirect);
    }

    return () => {mounted.current = false}
  }, [validToken, props.redirect, history]);

  function validateForm() {
    return props.user.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (mounted.current.timeout) {
      clearTimeout(mounted.current.timeout);
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };
    fetch(`${baseurl}/api/login?user=${props.user}&password=${password}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data.login === "success") {
          authService.setToken(data.token)
          setValidToken(true)
        }
        else {
          setValidToken(false)
          throw new Error("Invalid login")
        }
      }).catch((error) => {
        setValidToken(false)
        setAlert('Failed to login, ' + error.toString())
        setAlertVisible(true)
        mounted.current.timeout = setTimeout(() => {if(mounted.current) setAlertVisible(false);}, 5000);
      });
  }

  const current = new Date();
  const date = `${current.getFullYear()}.${current.getMonth()+1}.${current.getDate()}`;

  return (
  <div className="Login">
    <Form onSubmit={handleSubmit}>
      <Form.Group size="lg" controlId="email">
        <Form.Label>User</Form.Label>
        <Form.Control
          autoFocus
          value={props.user}
          onChange={(e) => props.setUser(e.target.value)}
        />
      </Form.Group>
      <Form.Group size="lg" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Button block size="lg" type="submit" disabled={!validateForm()} variant="signetik">
        Login
      </Button>
    </Form>
    <br />
    <Alert show={alertVisible} variant="warning">{alert}</Alert>
    <div style={{fontSize: "10px"}}>{date}</div>
  </div>
  )
}
