import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SortFilter } from "../SortFilter/SortFilter";
import CheckBox from "@material-ui/core/CheckBox";
import { authService } from "../../services/auth";
import { webSocketService } from '../../services/websocket';

import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'

import { baseurl, baseuri } from '../../services/backend';

function getFirmwares(token, start, end) {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + token }
    };
    console.log("getFirmwares")
  
    return new Promise((resolve, reject) => {
      fetch(`${baseurl}/api/firmwares?token=${token}&start=${start}&end=${end}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
  
          if (data.error) {
            reject("Invalid Response")
          }
          else {
              resolve(data)
          }
        });
    })
  }

function dateConvert(d) {
  var dt = new Date(d);
  return dt.toLocaleString();
}

function ConfirmationDialog(props) {
  const { onClose/*, selectedValue*/, open, handleYes, handleNo } = props;

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      onClose()
    }
  };

  var update_firmwares = props.firmwaresSelected;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal hidebackdropx="true" onClose={handleClose} open={open}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
    >
      <Box sx={{...style}}>
        <h4 id="child-modal-title">Firmware</h4>
        <Paper style={{maxHeight: 200, overflow: 'auto'}}>
          {
            update_firmwares && update_firmwares.length > 0 && update_firmwares.map((firmware) => (
              <div key={firmware.target + firmware.version} >Delete {firmware.target}:{firmware.version}?</div>
            ))
          }
        <Button
            onClick={handleNo}
            sx={{ mr: 1 }}
          >
            No
          </Button>
          <Button
            onClick={handleYes}
            variant="contained"
          >
            Yes
          </Button>
      </Paper>
      </Box>
    </Modal>
  );
}

function deleteFirmware(firmwareId) {
  var token = authService.getToken()

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
    body: JSON.stringify({ x: 'x' })
  };
  console.log("deleteFirmware firmware:" + firmwareId)

  return new Promise((resolve, reject) => {
    fetch(`${baseurl}/api/firmware/${firmwareId}?token=${token}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)

        if (data.error !== "false") {
          reject("Invalid Response")
        }
        else {
            resolve(data)
        }
      });
  })
}

function firmwaresDelete(firmwares) {
  console.log('firmwaresDelete');
  try {
    console.log('for each firmware');
    firmwares.forEach((firmware) => {
      console.log(firmware._id + ":" + firmware.target + ":" + firmware.version);
      deleteFirmware(firmware._id);
    })
  }
  catch (error) {
    console.log(error)
  }
}
  
export function Firmware(props) {
  const [open, setOpen] = React.useState(false);
  const [firmwaresSelected, setFirmwaresSelected] = React.useState(0);
  //const [selectedValue /*, setSelectedValue*/] = React.useState("");
  const [page, setPage] = useState(1);
  const [stride/*, setStride*/] = useState(10);
  const [count, setCount] = useState(0);
  const [firmwares, setFirmwares] = useState("");
  const history = useHistory()
  const [refresh, setRefresh] = useState(0);

  let startFirmware = (page-1)*stride;
  let endFirmware = page*stride-1;

  useEffect(() => {
    const messageHandler = (evt) => {
      console.log("ws message: " + evt.type + "," + evt.data);
      //if (!deviceTimer) {
        setTimeout(()=>{
          //getDevices(newToken, startDevice, endDevice, textQuery)
          setRefresh(Date.now())
          },2000);
      //}
    };

    var newToken = authService.getToken()

    webSocketService.subscribe(messageHandler, "firmwares");

    try {
      console.log("Requesting firmwares [" + newToken + "] " + startFirmware + " -> " + endFirmware)
      getFirmwares(newToken, startFirmware, endFirmware)
        .then((result) => {
          console.log("count: " + result.count)
          setCount(result.count)

          setFirmwares(result.firmwares)
        })
        .catch((error) => {
          console.log(error)
          console.log("redirecting to /login")
          authService.clrToken()
          history.push("/login")
        })
    }
    catch (error) {
        console.log(error)
        history.push("/login")
    }

    return () => {
      webSocketService.unsubscribe(messageHandler);
    }
  }, [props, history, page, stride, refresh])

  if (!firmwares) {
    return <div>Loading...</div>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (evt) => {
    console.log("handleClose");
    setOpen(false);
  };

  const handleYes = (evt) => {
    console.log("handleYes")
    console.log(firmwares)
    firmwaresDelete(firmwaresSelected);
    setOpen(false);
  };

  const handleNo = (evt) => {
    console.log("handleNo")
    setOpen(false);
  };

  const countSelectedFirmwares = () => {
    setFirmwaresSelected(firmwares.filter(firmware => firmware.checked));
  }

  const firmwareAction = (evt) => {
    console.log("do action: " + evt);

    if (evt === "delete") {
      handleClickOpen();
    }
  };

  const handleCBChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    version,
    target
  ) => {
    console.log("cb change");
    var firmware = firmwares.find((firmware) => firmware.version === version && firmware.target === target);
    if (firmware) {
      console.log(event.target.checked);
      firmware.checked = event.target.checked;
    }
    countSelectedFirmwares();
  };

  console.log(firmwares)

  const bulkItems = [
    {"text": "Delete firmware", "action": "delete"}
  ]

  return (
    <Container fluid="true" className="Container">
      <SortFilter
        bulkItems={bulkItems}
        onSearchChange={props.onSearchChange}
        setPage={setPage}
        itemsSelected={firmwaresSelected.length}
        action={firmwareAction}
        currentPage={page}
        count={count}
        stride={stride}
        title="Firmwares"
      />
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        firmwares={firmwares}
        firmwaresSelected={firmwaresSelected}
        handleYes={handleYes}
        handleNo={handleNo}
      />

      <Table striped bordered hover variant="signetik" className="devices-list">
        <thead>
          <tr />
          <tr>
            <td variant="signetik">SELECT</td>
            <td variant="signetik">VERSION</td>
            <td variant="signetik">TARGET</td>
            <td variant="signetik">CREATED</td>
            <td variant="signetik">USE</td>
            <td variant="signetik">ASSIGNED</td>
          </tr>
        </thead>
        <tbody className="table-content">
          {firmwares.map((firmware, i) => (
            <tr data-_id={firmware._id} key={firmware._id}>
              <td className="checkbox-cell">
                <label className="checkbox">
                  <CheckBox
                    className="checkbox-icon"
                    color="default"
                    onChange={(event) => {
                      handleCBChange(event, firmware.version, firmware.target);
                    }}
                  />
                </label>
              </td>
              <td>
                <div className="device-id">{firmware.version}</div>
              </td>
              <td>{firmware.target}</td>
              <td>{dateConvert(firmware.created)}</td>
              <td>{firmware.count}</td>
              <td>{firmware.assigned}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
