import { Modal, Box, Paper, Button, TextField } from '@material-ui/core';

export function CreateDialog(props) {
    const { onClose, open, deviceId, imei, onInputChange } = props;
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    };
  
    return (
      <Modal hidebackdrop="true" onClose={onClose} open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <h4 id="child-modal-title">Create</h4>
          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
            <TextField
              id="device-id"
              label="Device ID"
              variant="outlined"
              fullWidth
              margin="normal"
              value={deviceId}
              onChange={(e) => onInputChange('deviceId', e.target.value)}
            />
            <TextField
              id="imei"
              label="IMEI"
              variant="outlined"
              fullWidth
              margin="normal"
              value={imei}
              onChange={(e) => onInputChange('imei', e.target.value)}
            />
          </Paper>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button variant="contained" onClick={() => { onInputChange('ready', true)} }>
              OK
            </Button>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
  