
import { baseurl } from './backend';

class AuthService {

    canActivate() {
      console.log('auth canActivate')
      if (this.isLoggedIn()) {
        return true;
      }
      // Not logged in so redirect to login page with the return url
      //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  
    setToken(token) {
      console.log("set token: " + token);
      sessionStorage.setItem('token', token);
    }
  
    getToken() {
      var token = sessionStorage.getItem('token');
      console.log("get token: " + token);
      return token;
    }
  
    clrToken() {
      console.log("clear token");
      sessionStorage.removeItem('token');
    }
  
    parseJwt () {
      var token = this.getToken()
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
  
        return JSON.parse(jsonPayload);
      }
  
      return null;
    }
  
    isMemberOfGroup(group) {
      var jwt = this.parseJwt();
  
      if (jwt && jwt.groups && jwt.groups.includes(group)) {
        return true;
      }
  
      return false;
    }
  
    isLoggedIn() {
      console.log('token: ' + this.getToken());
      var jwt = this.parseJwt();
      if (jwt) {
        console.log(jwt);
      }
      if (this.getToken()) {
        return true;
      }
      return false;
    }
    
    validateToken() {
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        };
        console.log("validate Existing Token")
    
        return new Promise((resolve, reject) => {
          fetch(`${baseurl}/api/login?token=${this.getToken()}`, requestOptions)
            .then(response => response.json())
            .then(data => {
              console.log(data)
              if (data.error === "true") {
                console.log("Token Not Valid")
                reject()
              }
              else {
                console.log("Token Valid")
                resolve()
              }
            });
        })
      }
    
  }

  export const authService = new AuthService()
