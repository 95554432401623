import { baseurl, baseuri, wsproto } from "./backend";
import { authService } from "./auth";

class DatabaseService {
  constructor() {}

  getDevices(token, start, end, textQuery) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    };
    console.log("getDevices");

    return new Promise((resolve, reject) => {
      fetch(
        `${baseurl}/api/devices?start=${start}&end=${end}&textQuery=${textQuery}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error) {
            reject("Invalid Response");
          } else {
            resolve(data);
          }
        });
    });
  }
  getCertificate(token, devid) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    };
    console.log("getCertificate: " + devid);

    return new Promise((resolve, reject) => {
      fetch(`${baseurl}/api/certificates/${devid}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error) {
            resolve(data);
          } else {
            resolve(data);
          }
        });
    });
  }

  getFirmwares(token, start, end) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    };
    console.log("getFirmwares");

    return new Promise((resolve, reject) => {
      fetch(
        `${baseurl}/api/firmwares?start=${start}&end=${end}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error) {
            reject("Invalid Response");
          } else {
            resolve(data);
          }
        });
    });
  }

  putFirmware(token, deviceId, firmwareId) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ firmware: firmwareId, x: "x" }),
    };
    console.log("putFirmware device:" + deviceId + ", firmware: " + firmwareId);

    return new Promise((resolve, reject) => {
      fetch(`${baseurl}/api/device/${deviceId}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error) {
            reject("Invalid Response");
          } else {
            resolve(data);
          }
        });
    });
  }
  deleteDevice(deviceId) {
    var token = authService.getToken();

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ x: "x" }),
    };
    console.log("deleteDevice device:" + deviceId);

    return new Promise((resolve, reject) => {
      fetch(`${baseurl}/api/device/${deviceId}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error !== "false") {
            reject("Invalid Response");
          } else {
            resolve(data);
          }
        });
    });
  }

  setPassphrase(passphrase) {
    var token = authService.getToken();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ x: "x" }),
    };
    console.log("set passphrase");

    return new Promise((resolve, reject) => {
      fetch(
        `${baseurl}/api/certificates?passphrase=${passphrase}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error !== "false") {
            reject("Invalid Response");
          } else {
            resolve(data);
          }
        });
    });
  }

  generateCertficateDevice(deviceId) {
    var token = authService.getToken();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ x: "x" }),
    };
    console.log("generate certificate for device:" + deviceId);

    return new Promise((resolve, reject) => {
      fetch(`${baseurl}/api/certificates/${deviceId}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error !== "false") {
            reject("Invalid Response");
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  deviceCreate(deviceId, imei) {
    var token = authService.getToken();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ x: "x" }),
    };
    console.log("createDevice device:" + deviceId + "," + imei);

    return new Promise((resolve, reject) => {
      fetch(`${baseurl}/api/devices/${deviceId}?imei=${imei}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);

          if (data.error !== "false") {
            reject("Invalid Response");
          } else {
            resolve(data);
          }
        });
    });
  }
}

// Exporting a singleton instance to be used across the application
export const databaseService = new DatabaseService();
