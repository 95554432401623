import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";

import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SortFilter } from "../SortFilter/SortFilter";
import CheckBox from "@material-ui/core/CheckBox";
import { authService } from "../../services/auth";
import { baseurl } from '../../services/backend';

function getCertificates(token, start, end) {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + token }
    };
    console.log("getCertificates")
  
    return new Promise((resolve, reject) => {
      fetch(`${baseurl}/api/certificates?token=${token}&start=${start}&end=${end}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data)
  
          if (data.error) {
            reject("Invalid Response")
          }
          else {
              resolve(data)
          }
        });
    })
  }

function dateConvert(d) {
  var dt = new Date(d);
  return dt.toLocaleString();
}

export function Certificates(props) {
  const [open, setOpen] = React.useState(false);
  const [certificatesSelected, setCertificatesSelected] = React.useState(0);
  const [selectedValue /*, setSelectedValue*/] = React.useState("");
  const [matchFirmwares, setMatchFirmwares] = React.useState([]);
  const [page, setPage] = useState(1);
  const [stride/*, setStride*/] = useState(10);
  const [count, setCount] = useState(0);
  const [certificates, setCertificates] = useState("");
  const mounted = React.useRef(false)
  const history = useHistory()
  
  let startCert = (page-1)*stride;
  let endCert = page*stride-1;

  useEffect(() => {
    mounted.current = true
    var newToken = authService.getToken()
    try {
      if (mounted.current) {
        console.log("Requesting certificates [" + newToken + "] " + startCert + " -> " + endCert)
        getCertificates(newToken, startCert, endCert)
            .then((result) => {
            if (mounted.current) {
                console.log("count: " + result.count)
                setCount(result.count)      
                setCertificates(result.certificates);
            }
            })
            .catch((error) => {
            console.log(error)
            console.log("redirecting to /login")
            authService.clrToken()
            history.push("/login")
            })
      }
    }
    catch (error) {
        console.log(error)
        history.push("/login")
    }

    return function cleanup() {
      mounted.current = false
    }
  }, [props, history, page, stride])

  if (!certificates) {
    return <div>Loading...</div>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const countSelectedCertificates = () => {
    setCertificatesSelected(certificates.filter(certificate => certificate.checked));
  }

  const certificateAction = (evt) => {
    console.log("do action: " + evt);

    if (evt === "assign-m1") {
      console.log(props.firmwares);
      setMatchFirmwares(
        props.firmwares.filter((firmware) => firmware.target === "m1")
      );
      handleClickOpen();
    }
    if (evt === "assign-b1") {
      setMatchFirmwares(
        props.firmwares.filter((firmware) => firmware.target === "b1")
      );
      handleClickOpen();
    }
  };

  const handleCBChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    devid
  ) => {
    console.log("cb change");
    var certificate = certificates.find((certificate) => certificate.devid === devid);
    if (certificate) {
      console.log(event.target.checked);
      certificate.checked = event.target.checked;
    }
    countSelectedCertificates();
  };

  console.log(certificates)

  const bulkItems = [
    {"text": "Delete certificate", "action": "delete"}
  ]

  return (
    <Container fluid="true" className="Container">
      <SortFilter
        bulkItems={bulkItems}
        onSearchChange={props.onSearchChange}
        setPage={setPage}
        itemsSelected={certificatesSelected.length}
        action={certificateAction}
        currentPage={page}
        count={count}
        stride={stride}
        title="Certificates"
      />

      <Table striped bordered hover variant="signetik" className="devices-list">
        <thead>
          <tr />
          <tr>
            <td variant="signetik">SELECT</td>
            <td variant="signetik">DEVID</td>
            <td variant="signetik">PRIVCERT</td>
            <td variant="signetik">PRIVKEY</td>
            <td variant="signetik">CACERT</td>
            <td variant="signetik">CREATED</td>
            <td variant="signetik">EXPIRES</td>
          </tr>
        </thead>
        <tbody className="table-content">
          {certificates.map((certificate, i) => (
            <tr data-_id={certificate._id} key={certificate._id}>
              <td className="checkbox-cell">
                <label className="checkbox">
                  <CheckBox
                    className="checkbox-icon"
                    color="default"
                    onChange={(event) => {
                      handleCBChange(event, certificate.devid);
                    }}
                  />
                </label>
              </td>
              <td>
                <div className="device-id">{certificate.devid}</div>
              </td>
              <td>{certificate.privcert}</td>
              <td>{certificate.privkey}</td>
              <td>{certificate.cacert}</td>
              <td>{dateConvert(certificate.created)}</td>
              <td>{dateConvert(certificate.expiration)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
