import { Modal, Box, Paper, Button, TextField } from '@material-ui/core';

export function SetPassphraseDialog(props) {
  const { onClose, open, onInputChange } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal hidebackdrop="true" onClose={onClose} open={open}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }}>
        <h4 id="child-modal-title">Create</h4>
        <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
          <TextField
            id="passphrase"
            label="Passphrase"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => onInputChange('passphrase', e.target.value)}
          />
        </Paper>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="contained" onClick={() => { onInputChange('ready', true)} }>
            OK
          </Button>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}