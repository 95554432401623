import Header from "./components/Layout/Header/Header"
import './App.css';

import Login from "./components/Login/Login"
import './styles/theme.css';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import React, { useState } from "react"
import { Devices } from "./components/Devices/Devices"
import { Firmware } from "./components/Firmware/Firmware"
import { Certificates } from "./components/Certificates/Certificates"
import { Admin } from "./components/Admin/Admin"

function App() {
  const [user, setUser] = useState("");

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/login" key="/" >
            <Login user={user} setUser={setUser} redirect="/devices" />
          </Route>
          <Route path="/devices" key="/devices">
            <Devices />
            {/*<ConfigDebug config={config} />*/}
          </Route>
          <Route path="/firmware" key="/firmware">
            <Firmware />
            {/*<ConfigDebug config={config} />*/}
          </Route>
          <Route path="/certificates" key="/certificates">
            <Certificates />
            {/*<ConfigDebug config={config} />*/}
          </Route>
          <Route path="/admin" key="/admin">
            <Admin />
            {/*<ConfigDebug config={config} />*/}
          </Route>
          <Route exact path="/">
            <Redirect to="/devices" />
          </Route>
          <Route exact path="/index.html">
            <Redirect to="/devices" />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
