import React from "react"
import LogoImg from "../../../images/Signetik Logo_Color_HZ_Registered.png"
import { Col, Container, Nav, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

import "./Header.css"

function Menu() {
  return (
    <div>
      <Nav className="justify-content-center" variant="signetik">
        <Nav.Item>
          <Nav.Link as={Link} to="/devices" className="nav-link-signetik" variant="signetik">Devices</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/firmware" className="nav-link-signetik" variant="signetik">Firmware</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/certificates" className="nav-link-signetik" variant="signetik">Certificates</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/admin" className="nav-link-signetik" variant="signetik">Admin</Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  )
}

const Header = () => (
  <Container fluid="true" className="Container">
  <div className="banner">
    <img src={LogoImg} className="logo-img" alt="Signetik" />
    <div className="infobar">
      <div className="phone">402.421.2652</div>
      <div className="vertbar" />
      <div className="email">info@signetik.com</div>
    </div>
      <div className="bannerspace" />
  </div>
  <Row fluid="true">
        <Col>
          <Menu />
        </Col>
      </Row>
      </Container>
)

export default Header
