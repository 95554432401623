import { Modal, Box, Paper, Button } from '@material-ui/core';

export function ConfirmationDialog(props) {
    const { onClose, open, onInputChange, devicesSelected } = props;
  
    const handleYes = () => {
      onInputChange('devices', devicesSelected)
      onInputChange('ready', true)
    };
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    };
  
    return (
      <Modal hidebackdropx="true" onClose={onClose} open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      >
        <Box sx={{...style}}>
          <h4 id="child-modal-title">Delete Device(s)</h4>
          <Paper style={{maxHeight: 200, overflow: 'auto'}}>
            {
              devicesSelected && devicesSelected.length > 0 && devicesSelected.map((device) => (
                <div key={device.devid}>{device.devid}:{device.imei}?</div>
              ))
            }
          <Button
              onClick={onClose}
              sx={{ mr: 1 }}
            >
              No
            </Button>
            <Button
              onClick={handleYes}
              variant="contained"
            >
              Yes
            </Button>
        </Paper>
        </Box>
      </Modal>
    );
  }
  